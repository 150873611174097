import React from "react";
import { RecoilRoot } from "recoil";

import { Desktop } from "./Desktop";
import { Windows } from "./Windows";
import { Wallpaper } from "./Wallpaper";

export function App() {
  return (
    <RecoilRoot>
      <Wallpaper />
      <Desktop />
      <Windows />
    </RecoilRoot>
  );
}
