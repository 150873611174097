import { atom } from "recoil";

export const openWindowsState = atom({
  key: "openWindowsState",
  default: new Set(),
});

export const focusedWindowState = atom({
  key: "focusedWindowState",
  default: null,
});
