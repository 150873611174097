import { Circle } from "./Circle";

export class Container {
  constructor(p5) {
    this.p5 = p5;
    this.background = 0;
    this.maxR = null;
    this.circles = [];
    this.maxFrequency = 0.001;
    this.frequencyDelta = 0.01;
    this.maxSpeed = 2;
    this.speedDelta = 0.01;
    this.noiseOffset = 0;
  }

  setup() {
    this.maxR = this.p5.dist(0, 0, this.p5.width / 2, this.p5.height / 2);
  }

  addCircle() {
    this.circles.push(new Circle(this.p5, this.maxSpeed, this.noiseOffset));
    this.maxSpeed += this.p5.random(this.speedDelta);
    this.noiseOffset += 0.01;

    if (this.maxFrequency < 1) {
      this.maxFrequency += this.p5.random(this.frequencyDelta);
    } else {
      this.maxFrequency = 1;
    }
  }

  draw() {
    this.p5.background(this.background);

    this.circles.forEach((circle) => {
      circle.draw();
      circle.update();
    });
  }

  update() {
    const done = [];
    const rm = [];

    this.circles = this.circles.filter((circle, index) => {
      const valid = circle.r <= this.maxR;

      if (!valid) {
        done.push(circle);
      }

      for (let i = 0; i < index; i++) {
        if (this.circles[i].r < circle.r && !rm.includes(this.circles[i])) {
          rm.push(this.circles[i]);
        }
      }

      return valid;
    });

    this.circles = this.circles.filter((circle) => !rm.includes(circle));

    if (done.length >= 1) {
      this.updateBackground(done);
    }

    if (this.p5.random() < this.maxFrequency) {
      this.addCircle();
    }

    if (this.maxSpeed > 6) {
      this.maxFrequency = 0.001;
      this.frequencyDelta = 0.01;
      this.maxSpeed = 2;
      this.speedDelta = 0.01;
      this.addCircle();
    }
  }

  updateBackground(done) {
    const fastest = done.reduce((best, circle) => {
      if (best === null || circle.speed > best.speed) {
        return circle;
      }

      if (circle.speed < best.speed) {
        return best;
      }

      return circle;
    });

    this.background = fastest.color;
  }
}
