import React from "react";
import Sketch from "react-p5";

import { Container } from "./Container";

export function CirclesWallpaper() {
  let container;

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
    p5.noStroke();
    container = new Container(p5);
    container.setup();
    container.addCircle();
  };

  const windowResized = (p5) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    container.setup();
  };

  const draw = (p5) => {
    if (container) {
      p5.translate(p5.width / 2, p5.height / 2);
      container.draw();
      container.update();
    }
  };

  return <Sketch setup={setup} windowResized={windowResized} draw={draw} />;
}
