import React from "react";

import { Folder } from "../shared/Folder";
import { useRecoilState } from "recoil";
import { focusedProjectState } from "../../state/projects";
import { faEarth, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";

const items = [
  { icon: faEarth, label: "geoquiz.live", href: "https://geoquiz.live/" },
  { icon: faBitcoin, label: "21gag.com", href: "https://21gag.com" },
  { icon: faFlagCheckered, label: "tmcup.live", href: "https://tmcuplive.web.app/" },
  { icon: faTrophy, label: "ascension.direct", href: "https://ascensiondirect-9d2cb.web.app/" },
];
export function Projects() {
  const [focusedIcon, setFocusedIcon] = useRecoilState(focusedProjectState);

  const onDoubleClick = (href) => () => window.open(href, "_blank");

  return (
    <Folder
      items={items.map(({ href, ...item }) => ({
        ...item,
        onDoubleClick: onDoubleClick(href),
      }))}
      focusedIcon={focusedIcon}
      setFocusedIcon={setFocusedIcon}
    />
  );
}
