import { isMobile } from "react-device-detect";
import Draggable from "react-draggable";

export function DesktopDraggable({ children, ...props }) {
  if (isMobile) {
    return children;
  }

  return <Draggable {...props}>{children}</Draggable>;
}
