import React from "react";
import Draggable from "react-draggable";
import { Window as DesktopWindow, TitleBar } from "react-desktop/macOs";
import clsx from "clsx";

export function Window({
  title,
  width,
  height,
  defaultPosition,
  focused,
  children,
  onMouseDown,
  onStart,
  onClose,
}) {
  return (
    <Draggable
      bounds="body"
      handle=".handle"
      defaultPosition={defaultPosition}
      onStart={onStart}
      onMouseDown={onMouseDown}
    >
      <DesktopWindow
        chrome
        width={width}
        height={height}
        padding={0}
        className={clsx("Window", focused && "focused")}
        background="#0a3d62"
      >
        <TitleBar
          title={title}
          controls
          className="handle"
          onCloseClick={onClose}
        />
        {children}
      </DesktopWindow>
    </Draggable>
  );
}
