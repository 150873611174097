import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBVeSyHqdBKYPqRatI1ayNivxZykf0g_7A",
  authDomain: "samypw-be80c.firebaseapp.com",
  projectId: "samypw-be80c",
  storageBucket: "samypw-be80c.appspot.com",
  messagingSenderId: "129090383449",
  appId: "1:129090383449:web:5f53f0f4f891e2bb8a8b48",
  measurementId: "G-0DZDE96GWL",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
