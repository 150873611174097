import React from "react";

import { Folder } from "../shared/Folder";
import { useRecoilState } from "recoil";
import { focusedProfileState } from "../../state/profiles";
import {
  faGithub,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";

const items = [
  { icon: faGithub, label: "GitHub", href: "https://github.com/samybob1" },
  {
    icon: faLinkedin,
    label: "LinkedIn",
    href: "https://www.linkedin.com/in/slaumonier",
  },
  {
    icon: faCode,
    label: "CodinGame",
    href: "https://www.codingame.com/profile/364dbd8fd5d06c68819f176497f4288d522681",
  },
  {
    icon: faInstagram,
    label: "Instagram",
    href: "https://www.instagram.com/samylmnr/",
  },
];

export function Profiles() {
  const [focusedIcon, setFocusedIcon] = useRecoilState(focusedProfileState);

  const onDoubleClick = (href) => () => window.open(href, "_blank");

  return (
    <Folder
      items={items.map(({ href, ...item }) => ({
        ...item,
        onDoubleClick: onDoubleClick(href),
      }))}
      focusedIcon={focusedIcon}
      setFocusedIcon={setFocusedIcon}
    />
  );
}
