import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useDoubleTap } from "use-double-tap";

import { DesktopDraggable } from "./DesktopDraggable";

import "./Folder.css";

export function Folder({ items, focusedIcon, setFocusedIcon }) {
  const onFocus = (index) => () => setFocusedIcon(index);
  const onClear = (event) => {
    if (event.target === event.currentTarget) {
      setFocusedIcon(null);
    }
  };

  return (
    <div className="Folder" onClick={onClear}>
      {items.map(({ icon, label, onDoubleClick }, index) => (
        <Item
          key={index}
          icon={icon}
          label={label}
          focused={focusedIcon === index}
          onDoubleClick={onDoubleClick}
          onFocus={onFocus(index)}
        />
      ))}
    </div>
  );
}

function Item({ icon, label, focused, onDoubleClick, onFocus }) {
  const bind = useDoubleTap(onDoubleClick);

  return (
    <DesktopDraggable bounds="parent" onStart={onFocus} onMouseDown={onFocus}>
      <div className={clsx("Item", focused && "focused")} {...bind}>
        <FontAwesomeIcon icon={icon} size="6x" />
        <div>{label}</div>
      </div>
    </DesktopDraggable>
  );
}
