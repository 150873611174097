import React from "react";
import { useRecoilValue } from "recoil";

import { selectedWallpaperState } from "../../state/wallpaper";
import { PrimesWallpaper } from "../wallpapers/primes/PrimesWallpaper";
import { CirclesWallpaper } from "../wallpapers/circles/CirclesWallpaper";
import { WallpaperId } from "../../constants/wallpapers";

import "./Wallpaper.css";

const wallpapers = new Map([
  [WallpaperId.circles, CirclesWallpaper],
  [WallpaperId.primes, PrimesWallpaper],
]);

export function Wallpaper() {
  const selectedWallpaper = useRecoilValue(selectedWallpaperState);
  const Wallpaper = wallpapers.get(selectedWallpaper);

  return (
    <div className="Wallpaper">
      <Wallpaper />
    </div>
  );
}
