import React from "react";
import {
  faFolder,
  faIdCard,
  faImages,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState, useSetRecoilState } from "recoil";

import { focusedIconState } from "../../state/desktop";
import { WindowId } from "../../constants/windows";
import { focusedWindowState, openWindowsState } from "../../state/windows";
import { Folder } from "../shared/Folder";

import "./Desktop.css";

const items = [
  { icon: faFolder, label: "Projects", windowId: WindowId.projects },
  { icon: faIdCard, label: "Profiles", windowId: WindowId.profiles },
  { icon: faImages, label: "Wallpapers", windowId: WindowId.wallpapers },
];

export function Desktop() {
  const [focusedIcon, setFocusedIcon] = useRecoilState(focusedIconState);
  const setOpenWindows = useSetRecoilState(openWindowsState);
  const setFocusedWindow = useSetRecoilState(focusedWindowState);

  const onDoubleClick = (windowId) => () => {
    setOpenWindows((openWindows) => {
      const newValue = new Set(openWindows);
      newValue.add(windowId);
      return newValue;
    });

    setFocusedWindow(windowId);
  };

  return (
    <div className="Desktop">
      <Folder
        items={items.map(({ windowId, ...item }) => ({
          ...item,
          onDoubleClick: onDoubleClick(windowId),
        }))}
        focusedIcon={focusedIcon}
        setFocusedIcon={setFocusedIcon}
      />
    </div>
  );
}
