import React from "react";
import { useRecoilState } from "recoil";

import { Window } from "../shared/Window";
import { Wallpapers } from "../windows/wallpapers/Wallpapers";
import { Twitch } from "../windows/Twitch";
import { focusedWindowState, openWindowsState } from "../../state/windows";
import { WindowId } from "../../constants/windows";

import "./Windows.css";
import { Profiles } from "../windows/Profiles";
import { Projects } from "../windows/Projects";

const windows = new Map([
  [
    WindowId.profiles,
    { title: "Profiles", children: Profiles, width: 500, height: 500 },
  ],
  [
    WindowId.projects,
    { title: "Projects", children: Projects, width: 500, height: 500 },
  ],
  [WindowId.twitch, { title: "Twitch", children: Twitch }],
  [
    WindowId.wallpapers,
    {
      title: "Wallpapers",
      children: Wallpapers,
      width: 390,
      height: 440,
    },
  ],
]);

export function Windows() {
  const [openWindows, setOpenWindows] = useRecoilState(openWindowsState);
  const [focusedWindow, setFocusedWindow] = useRecoilState(focusedWindowState);

  const onMouseDown = (windowId) => () => setFocusedWindow(windowId);
  const onStart = (windowId) => () => setFocusedWindow(windowId);
  const onClose = (windowId) => () => {
    const newValue = new Set(openWindows);
    newValue.delete(windowId);
    setOpenWindows(newValue);
  };

  return (
    <div className="Windows">
      {[...openWindows].map((openWindow, index) => {
        const {
          title,
          children: Children,
          width = 1920 / 3,
          height = 1080 / 3,
        } = windows.get(openWindow);
        const defaultPosition = (index + 1) * 100;

        return (
          <Window
            key={openWindow}
            title={title}
            width={width}
            height={height}
            focused={focusedWindow === openWindow}
            defaultPosition={{ x: defaultPosition, y: defaultPosition }}
            onMouseDown={onMouseDown(openWindow)}
            onStart={onStart(openWindow)}
            onClose={onClose(openWindow)}
          >
            <Children />
          </Window>
        );
      })}
    </div>
  );
}
