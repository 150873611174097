import React from "react";
import { useRecoilState } from "recoil";
import clsx from "clsx";

import { selectedWallpaperState } from "../../../state/wallpaper";
import { WallpaperId } from "../../../constants/wallpapers";

import circles from "./images/circles.jpg";
import primes from "./images/primes.jpg";

import "./Wallpapers.css";

export function Wallpapers() {
  const [selectedWallpaperId, setSelectedWallpaperId] = useRecoilState(
    selectedWallpaperState
  );

  const onClick = (id) => () => setSelectedWallpaperId(id);

  return (
    <div className="Wallpapers">
      <img
        src={primes}
        alt="Primes"
        className={clsx(
          selectedWallpaperId === WallpaperId.primes && "selected"
        )}
        onClick={onClick(WallpaperId.primes)}
      />
      <img
        src={circles}
        alt="Circles"
        className={clsx(
          selectedWallpaperId === WallpaperId.circles && "selected"
        )}
        onClick={onClick(WallpaperId.circles)}
      />
    </div>
  );
}
