import React, { useCallback, useRef } from "react";
import ReactHlsPlayer from "react-hls-player";

const src =
  "https://video-weaver.sjc05.hls.ttvnw.net/v1/playlist/CowFXa0qicUI2QBTUa3h4URdspMjR-Z8HmnLgWhdn3cWjoOVcBx5jiJ73A21pjZhkAOlpYsucApZwB9_tuux5dgCM2T1zPzExrP5QDLqyNfUE6RkVBCpddevgphNbBU5GelbQi2Pcop2U0orrXJfJynS0sPYRo0KWpEcgKKutL4V08tDGcFduLTeH2n3X4MEMo0LhyQWRZiTbsfhOX_UaBTfWxjaLJ_gjq4CJ8dakc7GgAdACX5joEQk55zp6OVXYkUL8gKno1cPyNREwPPErQFyYSBMrOZt5RKFQpvOYE7YWHQyDng4Sz-iB4heXRzaAFI8ue5C9MO2wPFvde2wZQY8_sLTe45uo7_sY3Aw5T_UsMAVjOxIIv4-Qzk6e-RwrKGXM7-zGDqfXSUC5vs3EBAmsCWkrdw724vbhBndmWB1rfiu-mJnIo5LM9lRRTMDi2-1lmaGf10pZFi1oixMmQqcTba7VDtLAsxgbg-xwnySkJlcUe7NaM2uUhg-4yyS-1ZZ1BzjLGxpRxOW0hi0FvcPzQwxwxfboktFyCktnMZVFpXhK4OeZzuwQ0emRfvoMiSmes_-F5Qavftt_nyUMjkV9Kl0irWT_40L7gH_I-DtBaCPh3CcsdRL7fT5rIBMugaxMUxxspmd5ZqC1nTHZdIT1cFiyEDHcFEka9GHTl0nVbsSF21S4sleUuPNrd8DVWvEesoIJAwfbAwOwlaurflNXMO6Yz5t8j4kJOHWC3e6oZ11ug_9Ci3c_8iT19l_7Gs7mckQK7czjSvwB3vy48ATJIqennQ_YOkPcb-A6Wb7aMMU9m2YmRgzpRBAoIXK6p6vjn-aP45dvfaVye3XLdRastMYfT92m7mXR5cxaxoMHKn8se6kAwDA5BMaIAEqCWV1LXdlc3QtMjCPAw.m3u8";

export function Twitch() {
  const playerRef = useRef();

  const onClick = useCallback(() => {
    playerRef.current.play();
  }, [playerRef]);

  return (
    <ReactHlsPlayer
      playerRef={playerRef}
      src={src}
      autoPlay
      width="100%"
      height="auto"
      onClick={onClick}
    />
  );
}
