export class Circle {
  constructor(p5, maxSpeed, noiseOffset) {
    this.p5 = p5;
    this.r = 0;
    const noise = this.p5.noise(noiseOffset);
    const green = this.p5.int(this.p5.map(noise, 0, 1, 0, 255));
    this.color = this.p5.color(0, green, 0, 255);
    this.speed = this.p5.random(0.1, maxSpeed);
  }

  draw() {
    this.p5.fill(this.color);
    this.p5.circle(0, 0, this.r * 2);
  }

  update() {
    this.r += this.speed;
  }
}
